.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  z-index: 1000;
}

.shoppingCart {
  width: 60%;
  height: 90%;
  margin: 2rem auto;
  background-color: #ffffff;
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 22.1035px;

  display: flex;
  flex-direction: column;
  position: relative;
}

.cart-header {
  display: flex;
  justify-content: space-between;
}

.cart-header > h2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 45.5437px;
  line-height: 66px;
  text-align: center;
  color: #50bdff;
}

.empty-text {
  display: block;
  padding: 2rem;
  margin: auto;
}

.cart-products {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0;
  overflow-y: scroll;
  flex: 1;
  overflow-y: auto;
}

.cart-actions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remove-btn > span {
  padding-left: 10px;
  color: #cccccc;
}

.cart-actions > p {
  font-weight: 700;
}

.cart-footer-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkout-btn {
  width: 47.5%;
  text-align: center;
  border: 2px solid #7fcfff;
  border-radius: 11.7519px;
  padding: 12.2566px 42.0227px;
  color: #7fcfff;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  cursor: pointer;
}

.checkout-btn > a {
  margin-right: 0;
  color: #ffffff;
  width: 100%;
}

.checkout-btn:last-child {
  background-color: #50bdff;
  color: #ffffff !important;
  border: 0px;
}

@media all and (max-width: 1023px) {
  .shoppingCart {
    width: 90%;
    height: 90%;
    margin: 2rem auto;
    background-color: #ffffff;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 22.1035px;
  }

  .cart-header {
    display: none;
    justify-content: flex-end;
  }
}


@media all and (max-width: 1023px) {
  .checkout-btn:last-child {
    width: 100%;
  }
}