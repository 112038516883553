.items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.active {
  max-height: 1000px !important;
}

.items > li {
  list-style-type: none;
  gap: 10px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
}

.items > li:hover {
  color: #50bdff;
}
