.personal-account-container {
  padding: 5px 120px 115px 120px;
}

.personal-account-heading {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.personal-account-info {
  display: flex;
  flex-direction: row;
  gap: 107px;
}

.account-info-el > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.account-info-el > h3 {
  margin-top: 10px;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #50bdff;
}

.personal-account-buttons {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.personal-account-buttons > button {
  padding: 8.1px 17.8621px 10.2069px;
  border: 2px solid #50bdff;
  border-radius: 12.7586px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
  text-align: center;

  cursor: pointer;
}

.personal-account-orders {
  margin-top: 76px;
}

.blue-divider {
  height: 2px;
  background-color: #50bdff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 23px;
  opacity: 0.5;
}

.orders-el-top {
  display: grid;
  grid-template-columns: 2fr 0.3fr 0.3fr 0.3fr;
  align-items: center;
}

.orders-el-top > h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
}

.orders-el-top > p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #50bdff;
}

.orderitem-info > h6 {
  font-weight: 700;
  font-size: 15.9145px;
  line-height: 19px;
  color: #666666;
}

.orderitem-info > p {
  font-weight: 400;
  font-size: 12.378px;
  line-height: 15px;
  color: #666666;
}

.orderitem > p {
  font-weight: 400;
  font-size: 12.378px;
  line-height: 15px;
  color: #666666;
}

.orderitem-values {
  font-weight: 500 !important;
  font-size: 15.9145px !important;
  line-height: 19px !important;
  color: #666666 !important;
}

.orderitem {
  display: flex;
  align-items: center;
  gap: 62px;
}

.orderitem-info {
  gap: 8.8px;
}

.test {
  width: 130px;
  height: 70px;
  background-color: #50bdff;
  border-radius: 10.4px;
}

.mobile-order-num {
  display: none;
}

@media all and (max-width: 1439px) {
  .personal-account-container {
    padding: 20px 40px 100px 40px;
  }
}

@media all and (max-width: 1023px) {
  .personal-account-container {
    padding: 24px 20px 240px 20px;
  }

  .personal-account-heading {
    font-size: 40px;
    line-height: 100%;
  }

  .mobile-account-delimiter {
    margin-top: 20px;
    display: block;
    height: 2px;
    width: 100%;
    background: #50bdff;
  }

  .personal-account-info {
    margin-top: 30px;
    flex-direction: column;
    gap: 15px;
  }

  .personal-account-buttons {
    flex-direction: column;
  }
}

@media all and (max-width: 540px) {
  .orders-el-top > h5 {
    display: none;
  }

  .mobile-order-num {
    display: block !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .orders-el-top:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    gap: 36px;
  }

  .test {
    width: 79px;
    height: 44px;
  }

  .orders-el-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .orderitem {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .orderitem-info {
    max-width: 80px;
  }

  .orderitem-values {
    margin-bottom: 3%;
  }
}
