.subcategory--container {
    padding: 5px 120px 30px 120px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.subcategory {
    display: flex;
    justify-content: center;
    gap: 50px 75px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.subcategory--title {
    text-align: center;
    width: 100%;
    font-family: 'Random Grotesque Standard Bold';
    font-weight: 700;
    font-size: 64px;
    line-height: 92px;
    color: #50BDFF;
}

.category--card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.category--card > a {
    margin-right: 0;
}

.category--card > a > img {
    height: 190px;
    width: 350px;
    border-radius: 20px;
    object-fit: cover;
}

.category--name {
    margin-top: 17px;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #404040;
    text-align: center;
}

@media all and (max-width: 1439px) {

    .subcategory--container {
        padding: 20px 40px;
        gap: 50px;
    }

    .subcategory {
        gap: 30px 64px; 
    }

    .category--card > a > img {
        width: 272px;
        height: 147px;
    }

    .category--name {
        margin-top: 14px;
        width: 255px;
    }
}

@media all and (max-width: 1023px) {
    
    .subcategory--container {
        padding: 25px 0px;
        gap: 50px;
    }

    .subcategory {
        display: flex;
        justify-content: center;
        gap: 30px 64px; 
    }

    .subcategory--title {
        font-size: 40px;
        line-height: 100%;
    }

    .category--name {
        font-size: 18px;
    }

    .category--card {
        width: 100%;
    }

    .category--card > img {
        max-width: 350px;
        max-height: 190px;
        width: 100%;
        height: 100%;
    }
}
