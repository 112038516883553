.input-group {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-group > label {
    font-family: 'Formular';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #50BDFF;
}

.input-group-right > input {
    padding: 12px 10px;
    border: 1px solid #7fcfff;
    border-radius: 12px;
    width: 420px;

    font-family: 'Formular';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.input-group-right {
    position: relative;
    display: flex;
    flex-direction: column;
}

.error-message {
    margin-top: 10px;
    color: #D05151;
}

.redlabel {
    color: #D05151 !important;
}

.redform {
    border: 1px solid #D05151 !important;
}

.activate-promo {
    position: absolute;
    right: 1%;
    top: 10%;
    background-color: #50BDFF;
    padding: 2%;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.activate-promo:hover {
    background-color: #7fcfff;
}

.blue-message {
    color: #50BDFF;
}

@media all and (max-width: 1439px) {
    .input-group {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .input-group-right > input {
        margin-top: 10px;
    }

    .activate-promo {
        top: 20%;
        right: 0%;
        height: 80%;
    }
}