.swiper-wrapper {
  padding: 30px 20px 30px 0px;
}

.swiper-top {
  padding-left: 20px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swiper-cat-name {
  position: relative;
}

.swiper-cat-name > a {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #50bdff;
}

.blueline {
  width: 23px;
  height: 2px;
  background: #50bdff;
  position: absolute;
  left: -50px;
  top: 45%;
}

.swiper-pag-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.general-paginations {
  font-family: "Formular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #50bdff !important;
  user-select: none;
}

.swiper-pagination-fraction {
  width: auto !important;
}

.swiper-pagination-lock {
  display: block !important;
}

@media all and (max-width: 1023px) {
  .swiper-pag-nav {
    display: none;
  }
}
