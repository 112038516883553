.imageswiper > .swiper-pagination-fraction {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .imageswiper > .swiper-button-prev {
    color: #50bdff;
  }
  
  .imageswiper > .swiper-button-next {
    color: #50bdff;
  }
  
  .imageswiper > .swiper-pagination > .swiper-pagination-bullet-active {
    background: #50bdff;
  }
  
  .product--page--container {
    padding: 24px 120px;
  }
  
  .zuruck {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .product--info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .product--photo {
    margin-right: 50px;
  }
  
  .imageswiper {
    width: 500px;
  }
  
  .product-photo-img {
    object-fit: cover;
    border-radius: 27px;
    width: 487px;
    height: 386px;
  }
  
  .amount-btn > img {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
  
  .product--description {
    padding: 30px 40px;
    border: 2px solid #50bdff;
    border-radius: 20px;
  }
  
  .product--description > h2 {
    font-family: "Random Grotesque Standard Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
    color: #50bdff;
  }
  
  .product--description > p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
  }
  
  .product--description > hr {
    margin-top: 20px;
    border: 1px solid #50bdff;
  }
  
  .weight--buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .weight--buttons > button {
    border: 2px solid #50bdff;
    border-radius: 13px;
    padding: 8.1px 17.8621px 10.2069px;
    text-align: center;
  }
  
  .weight--buttons > button > a {
    margin-right: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #50bdff;
  }
  
  .product--description > h1 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #666666;
  }
  
  .order--buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .count--buttons {
    display: flex;
    gap: 18px;
    align-items: center;
  }
  
  .count--buttons > button {
    font-size: 25px;
    font-weight: 700;
    color: #50bdff;
  }
  
  .count--buttons > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #50bdff;
  }
  
  .order--button {
    padding: 12px 42px;
    background: #50bdff;
    border-radius: 12px;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #ffffff;
    cursor: pointer;
  }
  
  .order--button:hover {
    background-color: #7fcfff;
  }
  
  .comments {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 30px 40px;
    border: 2px solid #50bdff;
    border-radius: 20px;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 30px;
  }
  
  .instructions {
    display: flex;
    gap: 20px;
  }
  
  .instructions > button {
    border: 2px solid #50bdff;
    border-radius: 13px;
    padding: 8px 18px 10px 18px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #50bdff;
    cursor: pointer;
  }
  
  .button--active {
    color: #ffffff !important;
    background-color: #50bdff;
  }
  
  .comment--section--top {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .how--to--cook {
    margin-top: 30px;
  }
  
  .desc--section {
    margin-top: 30px;
  }
  
  .how_to_warm_up {
    margin-top: 30px;
  }
  
  .calories--section {
    margin-top: 30px;
  }
  
  .pricess {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .pricess > h1 {
    font-family: "Formular";
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #666666;
    margin-top: 10px;
  }
  
  .pricess > h2 {
    font-family: "Formular";
    font-weight: 700;
    color: #b3b0b0;
    font-size: 20px;
    text-decoration: line-through;
  }
  
  .user--comment--info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .user--comment--info > h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #50bdff;
    margin-right: 20px;
  }
  
  .stars {
    display: flex;
    gap: 6px;
  }
  
  .fa-star {
    color: #cccccc;
  }
  
  .checked {
    color: #50bdff;
  }
  
  .scroll {
    display: flex;
    gap: 21px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #50bdff;
  }
  
  .scroll > button {
    cursor: pointer;
  }
  
  .no-reviews {
    margin-top: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
  }
  
  .comment--text {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
  }
  
  .leave--comment {
    margin-top: 50px;
    background: #50bdff;
    border-radius: 12px;
    padding: 12px 42px;
  
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  
  .leave--comment:hover {
    background: #7fcfff;
  }
  
  @media all and (max-width: 1439px) {
    .product--page--container {
      padding: 20px 40px;
    }
  
    .product--photo > img {
      width: 487px;
      height: auto;
    }
  
    .product--description {
      padding: 30px 20px;
    }
  
    .product--description > h1 {
      margin-top: 40px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #666666;
    }
  
    .order--buttons {
      margin-top: 40px;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order--button {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  
    .comments {
      padding: 30px 40px;
    }
  }
  
  @media all and (max-width: 1023px) {
    .imageswiper > .swiper-button-prev {
      display: none;
    }
  
    .imageswiper > .swiper-button-next {
      display: none;
    }
  
    .product-photo-img {
      display: block;
      width: 100% !important;
      height: auto;
      max-height: 250px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .imageswiper {
      width: 90vw;
    }
  
    .product--page--container {
      padding: 50px 20px;
    }
  
    .product--info {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  
    .product--photo {
      margin-right: 0;
    }
  
    .product--description {
      height: 100%;
      width: 100%;
    }
  
    .weight--buttons > button > a {
      font-size: 15px;
    }
  
    .comments {
      margin-top: 20px;
      padding: 30px 20px;
    }
  
    .instructions {
      flex-direction: column;
    }
  
    .stars {
      margin-top: 10px;
    }
  
    .user--comment--info {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .leave--comment {
      text-align: center;
      width: 100%;
      font-size: 17px;
    }
  }
  