.page {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 5px 120px;
}

.expand {
  display: block;
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.expanded {
  max-height: 1300px;
  transition: max-height 0.3s ease-in-out;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.block > h3 {
  font-family: Random Grotesque Standard Bold;
  font-size: 64px;
  color: #50bdff;
}

.block > p {
  font-weight: 400;
  margin-top: 30px;
  font-size: 20px;
  color: #666666;
}

.block > iframe {
  margin-top: 20px;
}

.stage {
  display: flex;
  flex-direction: column;
}

.stage > h4 {
  margin-top: 40px;
  font-weight: 700;
  font-size: 32px;
  color: #50bdff;
}

.stage > p {
  margin-top: 30px;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
}

.stage > img {
  width: 45%;
  margin: 0 auto;
  margin-top: 30px;
  max-height: 600px;
  object-fit: cover;
  border-radius: 20px;
  object-position: 20%;
}

.icons {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  justify-content: space-between;
  gap: 40px;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 365px;
  min-height: 360px;
}

.icon > img {
  height: 150px;
}

.icon > h5 {
  font-weight: 500;
  font-size: 24px;
  color: #50bdff;
  margin-top: 20px;
}

.icon > p {
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
  text-align: center;
}

.links {
  width: 100%;
  margin-top: 50px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links > a {
  width: 100%;
  max-width: 366px;
  padding: 50px;
  border-radius: 23px;
  border: 3px #7fcfff solid;
  color: #7fcfff;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
}

@media all and (max-width: 1024px) {
  .page {
    padding: 30px 20px;
  }

  .block > iframe {
    width: 100%;
  }

  .block > h3 {
    text-align: center;
  }

  .links {
    flex-direction: column;
    gap: 20px;
  }
}
