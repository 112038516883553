.register-form {
  margin-top: 55px;
  width: 433px;
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.already-exists {
  color: #d05151;
}

@media all and (max-width: 1023px) {
  .login-heading {
    font-size: 45.5px;
  }

  .register-form {
    width: 100%;
  }

  .login-oauth {
    width: 100%;
  }
}