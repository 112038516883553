.for--partners--container {
    padding: 5px 120px;
}

.for--partners--container > h1 {
    font-family: 'Random Grotesque Standard Bold';
    font-weight: 700;
    font-size: 64px;
    line-height: 92px;
    text-align: center;
    color: #50BDFF;
}

.intro {
    display: flex;
    justify-content: space-between;
    gap: 122px;
    align-items: center;
    margin-top: 50px;
}

.intro > img {
    width: 487px;
    height: 540px;
    object-fit: cover;
    border-radius: 20px;
}

.blue-light-text {
    color: #50BDFF;;
    font-weight: 700;
    font-size: 18px;
}

.intro--info {
    display: flex;
    flex-direction: column;
}

.intro--info > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
    color: #666666;
}

.intro--text--start {
    margin-top: 20px;
}



.info--cta {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    align-items: center;
}

.info--cta > button {
    text-align: center;
    padding: 12px 42px;
    background: #50BDFF;
    border-radius: 12px;

    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF;
}

.info--cta > button > a {
    margin-right: 0;
    color: #FFFFFF;
}

.info--cta > p{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #50BDFF;

}

.info--blocks {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.info--block {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
}

.info--block > h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #50BDFF;
}

.info--block--images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 45px;
    margin-top: 45px;
}

.info--block--images > img {
    width: 370px;
    height: 201px;
    object-fit: cover;
    border-radius: 20px;
}

.info--block > p {
    margin-top: 58px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #666666;
}

.info--block > a {
    display: block;
    margin-top: 45px;
    padding: 12.2566px 42.0227px;
    background: #50BDFF;
    border-radius: 11.7519px;
    max-width: 490px;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF;
    align-self: center;
}

@media all and (max-width: 1439px) {
    .for--partners--container {
        padding: 0px 40px 30px 40px;
    }

    .intro {
        flex-direction: column;
        gap: 40px;
    }

    .intro > img {
        width: 100%;
    }

    .blue-light-text {
        color: #50BDFF;;
        font-weight: 700;
        font-size: 20px;
    }
    
    .intro--text--start {
        margin-top: 20px;
    }
    
    .intro--info > p {
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .info--block--images {
        justify-content: space-between;
        gap: 0px;
    }

    .info--block--images > img {
        width: 300px;
        height: 200px;
    }

}

@media all and (max-width: 1023px) {
    .for--partners--container {
        padding: 25px 20px 50px 20px;
    }

    .for--partners--container > h1 {
        font-size: 58px;
    }

    .intro > img {
        height: 223px;
    }

    .info--cta {
        display: flex;
        flex-direction: column;
    }

    .info--cta > button {
        width: 100%;
    }

    .info--block--images {
        justify-content: center;
      }
      
    .info--block--images img:nth-child(2),
    .info--block--images img:nth-child(3) {
        display: none;
    }

    .info--block > a{
        max-width: 100%;
        width: 100%;
        text-align: center;
    }
}