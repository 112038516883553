.footer--mobile--container {
  padding: 100px 20px 40px 20px;
}

.footer--mobile--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer--mobile--logo > img {
  width: 178px;
}

.footer--mobile--logo > p {
  font-weight: 400;
  font-size: 10.3019px;
  line-height: 13px;
  color: #9f9f9f;
}

.footer--mobile--socials {
  display: flex;
  gap: 15px;
  align-items: center;
}

.footer--mobile--socials > a {
  margin-right: 0;
}

.footer--mobile--socials > a > img {
  width: 49px;
}

.footer--mobile--bottom {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.footer--mobile--bottom--el > h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.footer--mobile--bottom--el > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9f9f9f;
}

.mobile--payment--methods {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}

.mobile--payment--methods > img {
  height: 20px;
}
