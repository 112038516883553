.offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer > h2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  color: #50bdff;
}

.cards {
  margin-top: 30px;
  display: flex;
  gap: 60px;
}

.card {
  flex: 1 1 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card > img {
  height: 120px;
}

.card > p {
  margin-top: 30px;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.card > p > span {
  font-weight: 600;
}

@media all and (max-width: 768px) {

  .offer {
    margin-top: 50px;
  }
  
  .offer > h2 {
    font-size: 40px;
    line-height: 100%;
  }

  .cards {
    flex-direction: column;
  }

  .card > p {
    align-self: flex-start;
  }
}
