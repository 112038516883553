.suggestions {
  margin-top: 120px;
}

.suggestions--top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.suggestions--title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #50bdff;
}

.cards--paginator {
  display: flex;
  gap: 21px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #50bdff;
  align-items: center;
}

.slick-slider {
  margin-top: 20px;
}

.slick-slide>div {padding: 30px 20px;}

.cards--paginator > button {
  cursor: pointer;
} 


@media all and (max-width: 1919px) {
  .slick-slide>div {padding: 20px 0px;}
}

@media all and (max-width: 1023px) {
  .slick-slide>div {padding: 20px 30px;}
}

@media all and (max-width: 767px) {
  .cards--paginator {
    display: none;
  }

}
