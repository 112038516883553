.exchange-policy-container {
  padding: 5px 120px 115px 120px;
}

@media all and (max-width: 1439px) {
  .exchange-policy-container {
    padding: 20px 40px 100px 40px;
  }
}

@media all and (max-width: 1023px) {
  .exchange-policy-container {
    padding: 24px 20px 240px 20px;
  }
}
