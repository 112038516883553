.order-product {
  margin-top: 40px;
  width: 100%;
}

.order-product-info > img {
  width: 82px;
  height: 44.5px;
  border-radius: 6.56px;
}

.nums-top > h3 {
  max-width: 280px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #404040;
}

.order-product-info {
  display: flex;
  flex-direction: row;
}

.order-product-numbers {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  justify-content: space-between;
}

.nums-top > p {
  margin-top: 9px;
}

.order--product--actions {
  margin-top: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grey {
  border: 0.884139px solid #cccccc;
  margin-top: 20px;
  margin-bottom: 15px;
}

.is-special-offer {
  color: #50bdff;
  font-weight: 600;
}

@media all and (max-width: 768px) {
  .nums-top > h3 {
    max-width: 150px;
  }
}
