.category--product--page--container {
  padding: 5px 120px;
  display: flex;
  gap: 30px 26.67px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.category--product--page--desc {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.category--product--page--desc > a {
  margin-right: 0;
}

.product--desc {
  position: relative;
}

.discountel {
  position: absolute;
  right: -5%;
  top: -5%;
  background: #fe9c80;
  padding: 18px 10.5px;
  width: 57px;
  height: 57px;
  border-radius: 67.26px;
}

.discountel > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14.1046px;
  line-height: 17px;
  color: #ffffff;
}

.back {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
}

.back > span {
  font-weight: 500;
  font-size: 24.3606px;
  line-height: 30px;
  color: #666666;
}

.category--product--page--desc > h1 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.hide {
  visibility: hidden;
  width: 100px;
}

@media all and (max-width: 1439px) {
  .category--product--page--container {
    padding: 20px 40px;
    gap: 0px 50px;
  }

  .product--card {
    margin-bottom: 50px;
  }
}

@media all and (max-width: 1023px) {
  .category--product--page--container {
    padding: 55px 20px;
    gap: 55px 20px;
  }

  .category--product--page--desc > hide {
    display: none;
  }

  .category--product--page--desc > h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
  }

  .product--card {
    margin-bottom: 0px;
  }

  .category--product--page--desc {
    flex-direction: row;
    gap: 20px;
  }

  .back > span {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}

@media all and (max-width: 390px) {
  .category--product--page--container {
    justify-content: center;
  }
}
