.search {
  position: relative;
}

.searchbox {
  display: flex;
  align-items: center;
  gap: 15px;
}

.searchbox > button {
  cursor: pointer;
  height: 24px;
  margin-left: 0;
}

.input {
  height: 30px;
  width: 250px;
  padding: 10px;
  border-radius: 15px;
  border-color: #50bdff;
  border-style: solid;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.results {
  position: absolute;
  z-index: 201;
  top: 50px;
}

.results > ul {
  list-style-type: none;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  background: #fff;
  overflow-y: scroll;
  max-height: 500px;
  scrollbar-color: #eeeeee #fff;
}

.results > ul > li > a {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  text-decoration: none;
  color: #50bdff;
  padding: 3px;
}

.results > ul > li:hover {
  background: rgb(240, 238, 238);
}

.results > ul > li > a > img {
  width: 55px;
  height: 35px;
}

@media all and (max-width: 1024px) {
  .searchbox {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    z-index: 10000000000;
    width: 100vw;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
  }

  .input {
    margin-left: 10px;
    padding-left: 20px;
    width: 100%;
    border: 0px;
    outline: none;
  }

  .input:focus {
    border: 2px solid #50bdff;
  }
  .cross {
    margin-right: 10px;
  }

  .results {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-height: 80vh;
    overflow: auto;
  }

  .results > ul {
    list-style-type: none;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    scrollbar-color: #eeeeee #fff;
    scrollbar-width: thin;
  }

  .results > ul > li > a {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    text-decoration: none;
    color: #50bdff;
    padding: 3px;
  }

  .results > ul > li:hover {
    background: rgb(240, 238, 238);
  }

  .results > ul > li > a > img {
    width: 55px;
    height: 35px;
  }
}
