@font-face {
    font-family: 'Formular';
    src: url('Formular-Black.eot');
    src: local('Formular Black'), local('Formular-Black'),
        url('Formular-Black.eot?#iefix') format('embedded-opentype'),
        url('Formular-Black.woff2') format('woff2'),
        url('Formular-Black.woff') format('woff'),
        url('Formular-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Formular';
    src: url('Formular-Light.eot');
    src: local('Formular Light'), local('Formular-Light'),
        url('Formular-Light.eot?#iefix') format('embedded-opentype'),
        url('Formular-Light.woff2') format('woff2'),
        url('Formular-Light.woff') format('woff'),
        url('Formular-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Formular';
    src: url('Formular.eot');
    src: local('Formular'),
        url('Formular.eot?#iefix') format('embedded-opentype'),
        url('Formular.woff2') format('woff2'),
        url('Formular.woff') format('woff'),
        url('Formular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Formular';
    src: url('Formular-Medium.eot');
    src: local('Formular Medium'), local('Formular-Medium'),
        url('Formular-Medium.eot?#iefix') format('embedded-opentype'),
        url('Formular-Medium.woff2') format('woff2'),
        url('Formular-Medium.woff') format('woff'),
        url('Formular-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Formular';
    src: url('Formular-Bold.eot');
    src: local('Formular Bold'), local('Formular-Bold'),
        url('Formular-Bold.eot?#iefix') format('embedded-opentype'),
        url('Formular-Bold.woff2') format('woff2'),
        url('Formular-Bold.woff') format('woff'),
        url('Formular-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}