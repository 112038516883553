.streetsinput {
  width: 100%;
}

.choicebar {
  border: 1px #50bdff solid;
  padding: 12px;
  border-radius: 12px;
}

.streets-input-group-right > li {
  list-style-type: none;
  gap: 10px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
}

.streets-input-group-right > li:hover {
  color: #50bdff;
}
