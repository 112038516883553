.header--mobile {
  position: relative;
  z-index: 500;
  padding-bottom: 30px;
}

.header--mobile--container {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
}

.burger {
  margin-left: auto;
}

.logotype--mobile {
  margin-left: auto;
}

.logotype--mobile > img {
  width: 105px;
}

.dropdown-menu-mobile {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 2;
}

.dropdown-menu-mobile > .header--mobile--container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  align-items: flex-end;
}

.dropdown--menu--top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dropdown-menu-mobile > .header--mobile--container > ul {
  margin-top: 40px;
  list-style-type: none;
  gap: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header--mobile--container > ul > li > a {
  text-decoration: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #50bdff;
}

.language_list{
  margin-left: 15px;
}

.input{
  margin-top: 20px;
}
.lupa-icon{
  width: 25px;
  height: 25px;
  margin-left: 15px;
}

.menu{
  width: 25px;
}

.cart{
  width: 25px;
}
@media all and (max-width: 365px) {
  .swiper-slide-active {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

