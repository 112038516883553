.product--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 280px;
  height: 344px;
  padding: 12px 15px 20px 15px;
  background: #ffffff;
  box-shadow: 20px 20px 25px rgb(0 0 0 / 20%);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.na-zakaz {
  position: absolute;
  left: 0%;
  top: -2%;
  background: #50bdff;
  padding: 12px 10.5px;
  border-radius: 10px;
}

.na-zakaz > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14.1046px;
  line-height: 17px;
  color: #ffffff;
}

.vegan {
  position: absolute;
  left: 0%;
  top: -2%;
  background: #50bdff;
  padding: 12px 10.5px;
  border-radius: 10px;
}

.vegan > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14.1046px;
  line-height: 17px;
  color: #ffffff;
}

.special-offer {
  position: absolute;
  right: -5%;
  top: -5%;
  background: #fe9c80;
  padding: 18px 10.5px;
  width: 57px;
  height: 57px;
  border-radius: 67.26px;
}

.special-offer > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: -3px;
}

.discount {
  position: absolute;
  right: -5%;
  top: -5%;
  background: #fe9c80;
  padding: 18px 10.5px;
  width: 57px;
  height: 57px;
  border-radius: 67.26px;
}

.discount > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14.1046px;
  line-height: 17px;
  color: #ffffff;
}

.xit {
  position: absolute;
  right: -5%;
  top: -5%;
  background: #fe9c80;
  padding: 18px 10.5px;
  width: 57px;
  height: 57px;
  border-radius: 67.26px;
}

.xit > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 2px;
}

.top-seller {
  position: absolute;
  right: -5%;
  top: -5%;
  background: #fe9c80;
  padding: 18px 10.5px;
  width: 57px;
  height: 57px;
  border-radius: 67.26px;
}

.top-seller > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  margin-left: -3px;
}

.sticker {
  position: absolute;
  left: 0%;
  top: -2%;
  background: #72D386;
  padding: 12px 10.5px;
  border-radius: 10px;
}

.sticker > span {
  font-family: "Formular";
  font-weight: 700;
  font-size: 14.1046px;
  line-height: 17px;
  color: #ffffff;
}

.card--photo {
  width: 250px;
  height: 146px;
  border-radius: 10px;
  object-fit: cover;
  user-select: none;
}

.card--description {
  margin-top: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card--name {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #404040;
  max-height: 44px;
  margin-right: 0;
  margin-bottom: 0;
  height: 66px;
}

.card--weight {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #404040;
  margin: 0;
  margin-top: 29px;
}

.buy-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card--price {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.old-price {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: line-through;
  color: #cccccc;
}

.card--button {
  padding: 5px 25px 10px 25px;
  background: #50bdff;
  border-radius: 12.7586px;

  text-align: center;

  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #f7f7f7;
}

.card--button:hover {
  cursor: pointer;
  background-color: #7fcfff;
}

.card--description--mobile {
  display: none;
}

@media all and (max-width: 1023px) {
  .product--card {
    padding: 0;
    width: 165px;
    height: 320px;
    box-shadow: 15px 12px 20px rgb(0 0 0 / 20%);
  }

  .vegan {
    left: 0;
    top: 0;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 20px;
    padding: 3px 10px;
  }

  .vegan > span {
    color: #fff;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
  }

  .na-zakaz {
    left: 0;
    top: 0;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 20px;
    padding: 3px 10px;
  }

  .na-zakaz > span {
    color: #fff;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
  }

  .discount {
    right: 0;
    top: 0;
    background: #ffffff;
    padding: 2px 12.5px;
    width: 60px;
    height: 26px;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 20px;
  }

  .discount > span {
    color: #fe9c80;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
  }

  .special-offer {
    right: 0;
    top: 0;
    background: #ffffff;
    padding: 2px 12.5px;
    width: 60px;
    height: 26px;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 20px;
  }

  .special-offer > span {
    color: #fe9c80;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
    margin-left: -6px;
  }

  .xit {
    right: 0;
    top: 0;
    background: #ffffff;
    padding: 2px 12.5px;
    width: 60px;
    height: 26px;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 20px;
  }

  .xit > span {
    color: #fe9c80;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
  }

  .top-seller {
    right: 0;
    top: 0;
    background: #ffffff;
    padding: 2px 12.5px;
    width: 60px;
    height: 26px;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 20px;
  }

  .top-seller > span {
    color: #fe9c80;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
  }

  .sticker {
    left: 0;
    top: 0;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 20px;
    padding: 3px 10px;
  }

  .product--card > a {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .card--photo {
    width: 100%;
    height: 164px;
    border: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card--description {
    display: none;
  }

  .card--description--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 5px 10px 10px 10px;
  }

  .card--name {
    max-width: 135px;
    max-height: 46px;
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card-meal {
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .card-meal > h6 {
    background: #50bdff;
    padding: 5px;
    color: #ffffff;
    border-radius: 10px;
    max-width: 60px;
  }

  .card--weight {
    margin-top: 0;
  }

  .price--and--weight {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .card--button {
    margin-top: 10px;
    width: 100%;
    padding: 5px 18px 10px;
    border-radius: 12.7586px;
    cursor: pointer;
  }

  .card--bottom {
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
