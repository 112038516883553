html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.main--container {
  padding: 60px 120px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.utp {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 48px;
  line-height: 110%;
  color: #50bdff;
  margin: 0;
}

.highlight {
  color: #fe9c80;
}

.utp--description {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #7fcfff;
  margin: 20px 0 0 0;
}

.cta--button {
  display: block;
  padding: 12px 42px;
  background: #50bdff;
  border-radius: 12px;
  margin-top: 40px;
  text-align: center;
  max-width: 300px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  margin-right: 0px;
}

.tray {
  position: absolute;
  right: -6%;
  mix-blend-mode: darken;
  z-index: 1;
  max-width: 920px;
  width: 100%;
}

.scheme {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  object-fit: cover;
}

.schememobile {
  display: none;
  object-fit: cover;
}

.blue-bg--img {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translateX(-50%);
  width: 120%;
  height: 1520px;
}

.svizha-numbers {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.svizha-numbers-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.svizha-num {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.svizha-num > h3 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 300;
  font-size: 70px;
  text-align: center;
  color: #fe9c80 !important;
}

.svizha-num > p {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--grey-40, #666);
}

.subcategoryName {
  margin-top: 200px;
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.vector {
  position: absolute;
}

.categories-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.svizha-partners {
  margin-top: 45px;
}

.categories-section > h2,
.svizha-numbers > h2,
.svizha-partners > h2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
  margin-bottom: 50px;
  z-index: 200;
}

.main-h2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
  margin-bottom: 0px;
  margin-top: 20px;
  z-index: 200;
}

.slidercategories {
  margin-top: 122px;
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.partners-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.partners-container > a {
  margin-right: 0;
  text-align: center;
}

.partners-container > a > img {
  width: 220px;
}

#partner2 {
  width: 150px;
}

.customer-review {
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 200px;
  padding: 25px;
  box-shadow: 5px 10px 25px 0px rgba(0, 146, 235, 0.2);
  border-radius: 20px;
  gap: 10px;
}

.customer-review > h3 {
  font-size: 18px;
  font-weight: 700;
  color: #50bdff;
}

.customer-review > p {
  font-weight: 400;
  font-size: 15px;
  color: #404040;
}

.customer-review img.review-image {
  display: none;
  object-fit: cover;
  border-radius: 15px;
}

.customer-review:hover {
  justify-content: center;
}

.customer-review:hover h3,
.customer-review:hover p {
  display: none;
}

.customer-review:hover img.review-image {
  display: block;
}

.customer-review:hover img {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reviews-swiper > .swiper-button-next,
.swiper-button-prev {
  color: #50bdff;
}

.reviews-swiper > .swiper-button-prev {
  color: #50bdff;
}

.advantages--card {
  max-width: 1000px;
  height: 585px;
  border-radius: 23px;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 5px 10px 25px rgba(0, 146, 235, 0.2);
  padding: 75px 83px 56px 83px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.advantages--heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.advantages--heading--logo {
  width: 122px;
  height: 44px;
}

.advantages--heading--text {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  color: #50bdff;
}

.advantages--component--container {
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}

.advantages--component {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
  max-height: 220px;
  justify-content: flex-end;
}

.advantages--component > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #50bdff;
  margin-top: 30px;
}

.advantages--card > a {
  background: #50bdff;
  border-radius: 15.1464px;
  padding: 16px 54px;
  text-align: center;
  margin-top: 63px;
  align-self: center;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 0;
}

.adv-logo-mobile {
  display: none;
}

@media all and (max-width: 1500px) {
  .tray {
    max-width: 700px;
  }
}

@media all and (max-width: 1439px) {
  .main--container {
    padding: 60px 40px;
  }

  .tray {
    width: 550px;
    top: 180px;
  }

  .utp {
    font-size: 33px;
    line-height: 110%;
  }

  .utp--description {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 16px 0 0 0;
  }

  .blue-bg--img {
    top: 450px;
  }

  .svizha-numbers {
    margin-top: 240px;
    margin-bottom: 0;
  }
  .svizha-numbers-content {
    flex-direction: column;
    gap: 30px;
  }

  .svizha-num > h3 {
    font-size: 55px;
  }

  .advantages--heading--text {
    font-size: 33px;
  }
}

@media all and (max-width: 1023px) {
  header {
    z-index: 3;
  }

  .burger {
    z-index: 2;
  }

  .main--container {
    padding: 0px 20px;
  }

  .tray {
    width: 100%;
    top: 7%;
    z-index: 1;
    right: 4%;
  }

  .firstscreen {
    margin-top: 520px;
  }

  .scheme > img {
    width: 700px;
  }

  .scheme {
    margin-top: 70px;
  }

  .blue-bg--img {
    top: 700px;
  }

  .utp {
    font-size: 36px;
  }

  .utp--description {
    font-size: 20px;
  }

  .cta--button {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 100%;
  }

  .svizha-numbers > h2 {
    margin-bottom: 0px !important;
  }

  .categories-section > h2,
  .svizha-numbers > h2,
  .svizha-partners > h2,
  .main-h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 50px;
    margin-top: 30px;
  }

  .partners-container {
    flex-direction: column;
    justify-content: center;
  }
  .advantages--card {
    margin-top: 30px;
    height: 100%;
    padding: 39px 25.5px;
  }

  .advantages--heading--logo {
    display: none;
  }

  .adv-logo-mobile {
    display: inline-block;
  }

  .advantages--component--container {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
}

@media all and (max-width: 767px) {
  .slidercategories {
    gap: 35px;
  }
  .tray {
    left: 50%;
    transform: translateX(-50%);
    max-width: 420px;
    top: 2%;
  }

  .mainbrs {
    display: none;
  }

  .blue-bg--img {
    width: 120%;
    top: 520px;
    height: 760px;
  }

  .firstscreen {
    margin-top: 265px;
  }

  .utp {
    font-size: 28px;
  }

  .schemecontent {
    display: none;
  }

  .schememobile {
    display: block;
  }

  .scheme > img {
    width: 336px;
  }

  .svizha-partners {
    margin-top: 0px;
  }

  .svizha-partners > h2 {
    margin-bottom: 0px;
  }

  .svizha-numbers {
    margin-top: 80px;
  }
}

@media all and (max-width: 375px) {
  .tray {
    top: 5%;
  }
}

@media all and (max-width: 373px) {
  .tray {
    top: 3%;
  }
}

@media all and (max-width: 330px) {
  .svizha-customers-reviews {
    display: none;
  }
}
