.fullw {
  width: 100%;
}

.cart-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
}

.cart-product-info {
  display: flex;
  flex-direction: row;
}

.product-info {
  flex-basis: 50%;
}

.product-info h3 {
  font-weight: 400;
  font-size: 1rem;
}

.cart-products img {
  max-width: 220px;
  height: 120px;
  background-color: #fff;
  border-radius: 17.5px;
  margin-right: 62px;
}

.cart-product-numbers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.numbers-top > h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.numbers-top > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #404040;
  margin-top: 8.85px;
}

.cart-product-numbers > span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #404040;
}

.product--actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.remove-btn > img {
  width: 21px;
  height: 24px;
  margin-right: 0;
}

.amount-btn {
  cursor: pointer;
}

.amount-btn > img {
  width: 40px;
  height: 40px;
  margin-right: 0;
}
.amount-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.amount-buttons > input {
  width: 36px;
  height: 24px;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #50bdff;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #50bdff;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .cart-product {
    flex-direction: column;
  }

  .product--actions {
    margin-top: 30px;
    align-items: flex-start;
    flex-direction: row;
  }

  .cart-product-numbers {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
  .amount-btn > img {
    width: 40px !important;
    height: 40px !important;
  }

  .cart-products img {
    width: 88px;
    height: 44px;
    border-radius: 6.56px;
    margin-right: 5px;
  }

  .numbers-top > h3 {
    font-size: 14px;
  }

  .cart-actions > button {
    display: none;
  }
  .cart-footer-buttons > button {
    width: 100%;
  }

  .cart-footer-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 16px;
  }
}
