* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    background-image: linear-gradient(to right, rgba(255,255,255, 0.5) 0 100%), url("./images/pattern.png");
    background-size: 100%;
    background-repeat: no-repeat;
    font-family: 'Formular';
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
    background-color: transparent;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-align: inherit;
    text-decoration: none; 
}

a {
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}