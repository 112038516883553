.custom-select {
  padding: 12px 10px;
  border-radius: 12px;
  border: 1px solid #7fcfff;
  min-width: 420px;
  max-width: 420px;
  position: relative;
}

.custom-select > .custom-select-arrows {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 0;
  transform: rotate(-90deg);
  transition: all 0.25s;
  outline: none;
  cursor: pointer;
}

.custom-select > .custom-select-arrows.activated {
  transform: rotate(0deg);
  right: 10px;
  top: 20px;
}

.custom-select
  > .custom-select-arrows.activated
  > .custom-select-arrow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-select-choice {
  cursor: pointer;
}

.custom-select-choice > p {
  user-select: none;
  max-width: 380px;
  overflow-x: hidden;
}

.custom-select
  > .custom-select-arrows
  > .custom-select-arrow-wrapper
  > span:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: rotate(45deg);
  background: #50bdff;
  width: 60%;
}

.custom-select
  > .custom-select-arrows
  > .custom-select-arrow-wrapper
  > span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 1px;
  transform: rotate(-45deg);
  background: #50bdff;
  width: 60%;
}

.custom-select > .custom-select-items {
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s;
}

.custom-select > .custom-select-items > p {
  cursor: pointer;
  margin-top: 18px;
  user-select: none;
}

.custom-select > .custom-select-items > p:hover {
  color: #50bdff;
}

.custom-select > .custom-select-items.activated {
  margin-top: 10px;
  overflow-y: auto;
  height: 100%;
  max-height: 600px;
}

.custom-select > p {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.custom-select-input {
  width: 80%;
  height: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: #50bdff 1px solid;
}

@media all and (max-width: 1439px) {
  .custom-select {
    width: 100%;
  }
}

@media all and (max-width: 1023px) {
  .custom-select {
    margin-top: 10px;
    min-width: 100%;
  }
}
