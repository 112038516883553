.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 33px 0px;
  height: 130px;
}

.lupa-icon {
  width: 30px;
  height: 30px;
}

.header--container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 120px;
}

nav {
  display: flex;
  gap: 30px;
}

.cart-icon > span {
  display: none;
}

.cart-icon-filled {
  position: relative;
}

.cart-icon-filled > span {
  position: absolute;
  top: 36%;
  left: 57%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12.3724px;
  line-height: 15px;
}

.burger {
  cursor: pointer;
}

nav > button {
  z-index: 3;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 525px;
  height: 100vh;
  background-image: url("../../images/dropdown-menu.svg");
  background-repeat: no-repeat;
  background-position: 70px -10px;
  z-index: 2;
}

.dropdown-menu > .header--container {
  padding: 130px 100px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.dropdown-menu > .header--container > ul {
  list-style-type: none;
}

.dropdown-menu > .header--container > ul > li {
  margin-bottom: 25px;
}

.dropdown-menu > .header--container > ul > li > a {
  text-decoration: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #50bdff;
}



  .language {
    font-weight: 500;
    color: #50bdff;
    font-size: 24px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;
  }
  .language_list{
    margin-right: 25px;
  }
  .language a {
    text-decoration: none;
    color: #50bdff;
    line-height: normal; 
    margin: 5px 0;
    display: block; 
  }
  
  .language summary {
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
  }
  
  .language summary::after {
    content: "";
    border: solid #50bdff;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.3s;
  }
  
  .language[open] > summary::after {
    transform: rotate(45deg);
  }
@media all and (max-width: 1439px) {
  .header--container {
    padding: 20px 40px;
  }

  .dropdown-menu > .header--container {
    padding: 120px 20px;
  }
}
