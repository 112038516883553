.accordion {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
}
.accordion-item {
  background-color: #fff;
  color: #404040;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 146, 235, 0.2);
  white-space:pre-wrap ;
}
.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordion-item-header::after {
  content: "\002B";

  font-size: 2rem;
  position: absolute;
  right: 1rem;
}
.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
.accordion-item-body-content {
  /* white-space: pre-line; */
  padding: 1rem;
  line-height: 1.5rem;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}
.accordion-item.expanded .accordion-item-body {
  max-height: 1500px;
  transition: all 0.4s ease-in;
}

.accordion-item-header.expanded::after {
  content: "\2212";
}
