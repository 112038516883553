.buy--us--container {
  padding: 5px 120px;
}

.buy--us--heading {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.buy--us--content {
  margin-top: 50px;
  display: flex;
  gap: 68px;
}

.map > iframe {
  border: 0;
  width: 600px;
  height: 520px;
}

.buy-us-splitter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.buy--us--text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 450px;
  max-height: 520px;
}

.buy--us--text > h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #50bdff;
  margin-bottom: 10px;
}

.buy--us--text > h4 {
  margin-top: 20px;
  color: #50bdff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.buy--us--text > p {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.buy-us-wannaorder {
  margin-top: auto;
  background: #50bdff;
  border-radius: 12px;
  padding: 12px 42px;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}

.buy-us-wannaorder {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  margin-right: 0;
}

@media all and (max-width: 1439px) {
  .buy--us--container {
    padding: 0px 40px 40px 40px;
  }

  .buy--us--content {
    flex-direction: column;
  }

  .map > iframe {
    border: 0;
    width: 100%;
    height: 520px;
  }

  .buy--us--text > button {
    margin-top: 40px;
    text-align: center;
  }
}

@media all and (max-width: 1023px) {
  .buy--us--container {
    padding: 25px 20px 50px 20px;
  }

  .buy--us--heading {
    font-size: 40px;
    line-height: 100%;
  }

  .map > iframe {
    max-height: 338px;
  }

  .buy-us-wannaorder {
    width: 100% !important;
    text-align: center;
  }

  .buy-us-splitter {
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
}
