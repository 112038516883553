.register-input {
  display: flex;
  flex-direction: column;
}

.register-input > label {
  font-family: "Formular";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #50bdff;
}

.register-input-group-mid {
  margin-top: 12.78px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: center;
}

.register-input-group-mid > img {
  color: #50bdff;
  width: 20px;
}

.register-input-group-mid > input {
  border: none;
  outline: none;
  width: 100%;
}

.register-input-group-mid > input::placeholder {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.error-placeholder > input::placeholder {
  color: #d05151 !important;
}

.register-underline {
  margin-top: 12.78px;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 7.51676px;
  background: #50bdff;
}

.red-register-underline {
  margin-top: 12.78px;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 7.51676px;
  background: #d05151;
}
