html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.Menu {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}
.maincontainer {
  padding: 0 120px 60px;
  display: flex;
  position: relative;
  flex-direction: column;
}

.utp {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 36px;
  line-height: 110%;
  color: #50bdff;
  margin: 0;
}

.highlight {
  color: #fe9c80;
}

.utpdescription {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #7fcfff;
  margin: 20px 0 0 0;
}

.ctabutton {
  display: block;
  padding: 21px 53px;
  background: #50bdff;
  border-radius: 12px;
  margin-top: 40px;
  text-align: center;
  max-width: 300px;
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
}

.firstscreen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  min-width: 50vw;
  width: 100%;
}

.tray {
  max-width: 40vw;
  object-fit: cover;
  width: 100%;
}

.svizhanumbers {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.svizhanumberscontent {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.svizhanum {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.svizhanum > h3 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 300;
  font-size: 70px;
  text-align: center;
  color: #fe9c80 !important;
}

.svizhanum > p {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--grey-40, #666);
}

.subcategoryName {
  margin-top: 200px;
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.vector {
  position: absolute;
}

.categoriessection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.svizhapartners {
  margin-top: 45px;
}

.categoriessection > h2,
.svizhanumbers > h2,
.svizhapartners > h2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
  margin-bottom: 50px;
  z-index: 200;
}

.mainh2 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
  margin-bottom: 0px;
  margin-top: 20px;
  z-index: 200;
}

.slidercategories {
  margin-top: 115px;
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.partners-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.partners-container > a {
  margin-right: 0;
  text-align: center;
}

.partners-container > a > img {
  width: 220px;
}

#partner2 {
  width: 150px;
}

.customerreview {
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 200px;
  padding: 25px;
  box-shadow: 5px 10px 25px 0px rgba(0, 146, 235, 0.2);
  border-radius: 20px;
  gap: 10px;
}

.customerreview > h3 {
  font-size: 18px;
  font-weight: 700;
  color: #50bdff;
}

.customerreview > p {
  font-weight: 400;
  font-size: 15px;
  color: #404040;
}

.customerreview img.reviewimage {
  display: none;
  object-fit: cover;
  border-radius: 15px;
}

.customerreview:hover {
  justify-content: center;
}

.customerreview:hover h3,
.customerreview:hover p {
  display: none;
}

.customerreview:hover img.reviewimage {
  display: block;
}

.customerreview:hover img {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reviewsswiper > .swiper-button-next,
.swiper-button-prev {
  color: #50bdff;
}

.reviewsswiper > .swiper-button-prev {
  color: #50bdff;
}

.advantagescard {
  max-width: 1000px;
  height: 585px;
  border-radius: 23px;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 5px 10px 25px rgba(0, 146, 235, 0.2);
  padding: 75px 83px 56px 83px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.advantagesheading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.advantagesheadinglogo {
  width: 122px;
  height: 44px;
}

.advantagesheadingtext {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  color: #50bdff;
}

.advantagescomponentcontainer {
  margin-top: 65px;
  display: flex;
  justify-content: space-between;
}

.advantagescomponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
  max-height: 220px;
  justify-content: flex-end;
}

.advantagescomponent > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #50bdff;
  margin-top: 30px;
}

.advantagescard > a {
  background: #50bdff;
  border-radius: 15.1464px;
  padding: 16px 54px;
  text-align: center;
  margin-top: 63px;
  align-self: center;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 0;
}

.advlogomobile {
  display: none;
}

@media all and (max-width: 1440px) {
  .Menu {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 100px;
  }

  .maincontainer {
    padding: 0 40px 60px;
  }

  .tray {
    width: 550px;
    top: 180px;
  }

  .utp {
    font-size: 32px;
    line-height: 110%;
  }

  .utpdescription {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 16px 0 0 0;
  }

  .svizhanumbers {
    margin-top: 240px;
    margin-bottom: 0;
  }
  .svizhanumberscontent {
    flex-direction: column;
    gap: 30px;
  }

  .svizhanum > h3 {
    font-size: 55px;
  }

  .advantagesheadingtext {
    font-size: 33px;
  }
}

@media all and (max-width: 1023px) {
  header {
    z-index: 3;
  }

  .burger {
    z-index: 2;
  }

  .maincontainer {
    padding: 0px 20px;
  }

  .firstscreen {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .tray {
    margin-top: 20px;
    width: 100vw;
    max-width: 100%;
  }

  .utp {
    font-size: 36px;
  }

  .utpdescription {
    font-size: 20px;
  }

  .ctabutton {
    position: relative;
    z-index: 3;
    width: 100%;
    font-size: 20px;
    max-width: 100%;
  }

  .svizhanumbers > h2 {
    margin-bottom: 0px !important;
  }

  .categoriessection > h2,
  .svizhanumbers > h2,
  .svizhapartners > h2,
  .mainh2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 50px;
    margin-top: 30px;
  }

  .partners-container {
    flex-direction: column;
    justify-content: center;
  }
  .advantagescard {
    margin-top: 30px;
    height: 100%;
    padding: 39px 25.5px;
  }

  .advantagesheadinglogo {
    display: none;
  }

  .advlogomobile {
    display: inline-block;
  }

  .advantagescomponentcontainer {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
}

@media all and (max-width: 767px) {
  .slidercategories {
    gap: 35px;
  }

  .mainbrs {
    display: none;
  }

  .utp {
    font-size: 28px;
  }

  .svizhapartners {
    margin-top: 0px;
  }

  .svizhapartners > h2 {
    margin-bottom: 0px;
  }

  .svizhanumbers {
    margin-top: 80px;
  }
}

@media all and (max-width: 330px) {
  .svizhacustomersreviews {
    display: none;
  }
}
