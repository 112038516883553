.order--page--container {
  padding: 5px 120px;
}

.order--user--buttons {
  display: flex;
  gap: 20px;
}

.order--heading {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.order--user--buttons {
  margin-top: 50px;
}

.zakreslena {
  color: #50bdff;
  text-decoration: line-through;
}

.order--user--buttons > button {
  padding: 8.1px 17.8621px 10.2069px;
  border-radius: 12.7586px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
  border: 2px solid #50bdff;
  cursor: pointer;
}

.active {
  color: #ffffff !important;
  background: #50bdff;
}

.order--content {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user--info {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #50bdff;
}

.fields {
  width: 590px;
}

.order--items {
  width: 47.5%;
}

.forms--el {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.forms--el > input {
  padding: 12px 10px;
  border: 1px solid #7fcfff;
  border-radius: 12px;
  width: 420px;
}

.delivery_policy_order {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.delivery_policy_order > h4 {
  color: #50bdff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.delivery_policy_order > p {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* Messenger */
.messenger-select {
  padding: 12px 10px;
  border-radius: 12px;
  border: 1px solid #7fcfff;
  min-width: 420px;
  position: relative;
}

.messenger-select > .arrowmess {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 0;
  transform: rotate(-90deg);
  transition: all 0.25s;
  outline: none;
  cursor: pointer;
}

.messenger-select > .arrowmess.activated {
  transform: rotate(0deg);
  right: 10px;
  top: 20px;
}

.messenger-select > .arrowmess > .arrow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.chosen-messenger {
  cursor: pointer;
}

.messenger-select > .arrowmess > .arrow-wrapper > span:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: rotate(45deg);
  background: #50bdff;
  width: 60%;
}

.messenger-select > .arrowmess > .arrow-wrapper > span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 1px;
  transform: rotate(-45deg);
  background: #50bdff;
  width: 60%;
}

.messenger-select > .messenger-items {
  height: 0;
  overflow: hidden;
  transition: all 0.25s;
}

.messenger-select > .messenger-items > p {
  cursor: pointer;
  margin-top: 18px;
  user-select: none;
}

.messenger-select > .messenger-items > p:hover {
  color: #50bdff;
}

.messenger-select > .messenger-items.activated {
  height: 115px;
}

.messenger-select > p {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
/* Messenger */

.delivery-method {
  padding: 12px 10px;
  border-radius: 12px;
  border: 1px solid #7fcfff;
  min-width: 420px;
  position: relative;
}

.delivery-method > .arrow {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 0;
  transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: all 0.25s;
  outline: none;
}

.delivery-method > .arrow.activated {
  transform: rotate(0deg);
  right: 10px;
  top: 20px;
}

.delivery-method > .arrow > .arrow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.choosen-method {
  cursor: pointer;
}

.delivery-method > .arrow > .arrow-wrapper > span:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: rotate(45deg);
  background: #50bdff;
  width: 60%;
}

.delivery-method > .arrow > .arrow-wrapper > span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 1px;
  transform: rotate(-45deg);
  background: #50bdff;
  width: 60%;
}

.delivery-method > .method-items {
  height: 0;
  overflow: hidden;
  transition: all 0.25s;
}

.delivery-method > .method-items > p {
  cursor: pointer;
  margin-top: 18px;
}

.delivery-method > .method-items > p:hover {
  color: #50bdff;
}

.delivery-method > .method-items.activated {
  height: 155px;
}

.delivery-method > p {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.forms--el > label {
  font-family: "Formular";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.checkbox {
  width: 20px !important;
  border: 1px solid #7fcfff;
  border-radius: 8.27586px;
}

.input-group:nth-child(8) {
  flex-direction: row-reverse;
  align-items: center;
}

.input-group:nth-child(8) > .input-group-right > input {
  width: 30px;
  height: 30px;
  border: 1px solid #7fcfff !important;
  border-radius: 8.27586px;
}

.input-group:nth-child(8) > .input-group-right > span {
  display: none;
}

.rozdiliti {
  margin-top: 40px;
  margin-bottom: 20px;
  border: 1px solid #7fcfff;
}

.wannaorder {
  margin-top: 40px;
  padding: 12.2566px 42.0227px;
  background: #50bdff;
  border-radius: 11.7519px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
}

.wannaorder:hover {
  background-color: #7fcfff;
}

.delivery,
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #404040;
  font-weight: 500;
}

.delivery {
  font-size: 14px;
  line-height: 17px;
}

.delivery > span {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #50bdff;
}

.summary {
  font-size: 24px;
  line-height: 29px;
  margin-top: 30px;
}

.summary > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.invisible {
  display: none;
}

.thanks-for-order-modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.thanks-for-order {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 90%;
  height: 75%;
  z-index: 11;
  border-radius: 23px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.thanks-for-order-container {
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.thanks-for-order-container > img {
  margin-bottom: 20px;
}

.thanks-for-order-container > h1 {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  color: #50bdff;
}

.thanks-for-order-container > p {
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #50bdff;
}

.thanks-for-order-container > a {
  margin-top: 40px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
}

.thanks-for-order-container > a > span {
  background: #50bdff;
  border-radius: 11.7519px;
  padding: 12.2566px 42.0227px;
}

.inf {
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.registered-order--content {
  display: flex;
  justify-content: center;
}

input[type="checkbox"] {
  height: 28px;
}



@media all and (max-width: 1439px) {
  .order--page--container {
    padding: 0px 40px 30px 40px;
  }

  .forms--el {
    flex-direction: column;
    align-items: flex-start;
  }

  .forms--el > select {
    width: 100%;
  }


  .dontcall {
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  .input-group > .input-group-right {
    width: 100%;
  }

  .input-group > .input-group-right > input {
    width: 100%;
  }

  .input-group:nth-child(8) {
    gap: 80px;
  }

  .input-group:nth-child(8) > .input-group-right {
    width: 30px;
  }

  .input-group:nth-child(8) > .input-group-right > input {
    margin-top: 0;
  }

  .messenger-select {
    width: 100%;
  }

  .delivery-method {
    width: 100%;
  }
}

@media all and (max-width: 1023px) {
  .order--page--container {
    padding: 25px 20px 60px 20px;
  }

  .order--heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #50bdff;
  }

  .invisible {
    display: block;
    margin-top: 20px;
    border: 2px solid #50bdff;
  }

  .order--user--buttons {
    flex-direction: column;
    text-align: center;
  }

  .order--content {
    flex-direction: column-reverse;
  }

  .order--items {
    width: 100%;
  }

  .messenger-select {
    margin-top: 10px;
    min-width: 100%;
  }

  .delivery-method {
    margin-top: 10px;
    min-width: 100%;
  }

  .summary {
    margin-top: 30px;
  }

  .fields {
    margin-top: 80px;
    width: 100%;
  }

  .thanks-for-order {
    height: 90%;
  }

  .thanks-for-order-container {
    padding: 30px;
  }

  .thanks-for-order-container > h1 {
    font-size: 35px;
    line-height: 40px;
  }
  input[type="checkbox"] {
    height: 15px;
  }
}
