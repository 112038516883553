.select {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.select > label {
  max-width: 170px;
  font-family: "Formular";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.wrapper {
  width: 420px;
  border-radius: 12px;
}

.header {
  width: 100%;
  border: none;
  border: 1px solid #7fcfff;
  border-radius: 12px;
  padding: 12px 10px;
  font-family: "Formular";
  font-weight: 400;
  font-size: 16px;
}

.header::placeholder {
  font-family: "Formular";
}

.body.active {
  max-height: 5000px;
}

.body {
  transition: max-height 0.5s ease-in-out;
  padding: 12px 10px;
  max-height: 0;
  overflow: hidden;
}

.body > p {
  margin-top: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.body > p:hover {
  color: #50bdff;
}

@media all and (max-width: 1439px) {
  .select {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .wrapper {
    width: 100% !important;
  }
}
