.order-login-form {
  width: 590px;
  max-width: 590px !important;
}

.order-login-form > h4 {
  font-family: "Formular";
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #50bdff;
}

.socials-logins {
  margin-top: 20px;
  display: flex;
  gap: 18px;
}

.socials-logins > button {
  width: 100%;
  padding: 17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  cursor: pointer;
}

.fb {
  background-color: #1877f2;
  border-radius: 8.50917px;
}

.fb > p {
  color: #ffffff;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 17.0183px;
  line-height: 20px;
}

.gg {
  background: #ffffff;
  box-shadow: 0px 0px 2.55275px rgba(0, 0, 0, 0.084),
    0px 1.70183px 2.55275px rgba(0, 0, 0, 0.168);
  border-radius: 8.50917px;
}

.gg > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 17.0183px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.54);
}

.abo-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  color: #50bdff;
}

.abo-divider > div {
  height: 2px;
  background: #50bdff;
  width: 260px;
}

@media all and (max-width: 620px) {
  .order-login-form {
    margin-top: 35px;
    width: 100%;
  }

  .socials-logins {
    flex-direction: column;
  }

  .abo-divider > div {
    width: 43%;
  }
}
