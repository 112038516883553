.login-container {
  padding: 5px 120px 150px 120px;
}

.login-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.login-main {
  display: flex;
  justify-content: space-around;
}

.login-form {
  margin-top: 37px;
  max-width: 433px;
}

.login-heading {
  text-align: center;
  font-family: "Formular";
  font-style: normal;
  font-weight: 700;
  font-size: 91.4556px;
  line-height: 112px;
  color: #50bdff;
}

.input-top > p {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #50bdff;
}

.input-mid {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.input-mid-left {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.input-mid-left > input {
  border: none;
  outline: none;
  width: 100%;
}

.input-mid-left > input::placeholder {
  font-family: "Formular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
}

.blue-underline {
  display: block;
  height: 2px;
  background: #50bdff;
  width: 100%;
  margin-top: 12.78px;
}

.input-password {
  margin-top: 38px;
}

.visible-password {
  cursor: pointer;
}

.input-rememberme {
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rememberme {
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  align-items: center;
}

.rememberme > input {
  width: 20px;
  height: 20px;
  border: 2.06897px solid #50bdff;
  border-radius: 5.51724px;
}

.rememberme > p {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #50bdff;
}

.input-rememberme > a {
  margin-right: 0;
  text-decoration: none;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #50bdff;
}

.login {
  background: #50bdff;
  border-radius: 11.7519px;
  padding: 12.2566px 42.0227px;
  width: 100%;
  text-align: center;
  margin-top: 62px;

  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;

  cursor: pointer;
}

.notregistered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 27px;
}

.notregistered > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.notregistered > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50bdff;
  text-decoration: underline;
}

.login-oauth {
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.facebook-auth {
  background: #1877f2;
  border-radius: 8.50917px;
  padding: 13.2px 78.64px;
  text-align: center;
}

.facebook-auth > a {
  display: flex;
  align-items: center;
  gap: 13px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 17.0183px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 0;
  justify-content: center;
}

.google-auth {
  background: #ffffff;
  box-shadow: 0px 0px 2.55275px rgba(0, 0, 0, 0.084),
    0px 1.70183px 2.55275px rgba(0, 0, 0, 0.168);
  border-radius: 8.50917px;
  padding: 13.2px 78.64px;
  text-align: center;
  cursor: pointer;
}

.google-auth > p {
  display: flex;
  align-items: center;
  gap: 13.5px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 17.0183px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 0;
  justify-content: center;
}

.mobile-divider {
  display: none;
}

@media all and (max-width: 1439px) {
  .login-container {
    padding: 20px 40px 100px 40px;
  }

  .login-vector {
    display: none;
  }
}

@media all and (max-width: 1023px) {
  .login-container {
    padding: 24px 20px 228px 20px;
  }

  .login-main {
    flex-direction: column-reverse;
    align-items: center;
  }

  .mobile-delimiter {
    margin-top: -30px;
    margin-bottom: 20px;
    display: block;
    height: 2px;
    width: 100%;
    background: #50bdff;
  }

  .mobile-divider > span {
    height: 2px;
    width: 50%;
    background: #50bdff;
  }

  .mobile-divider > p {
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #50bdff;
  }

  .mobile-divider {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}
