.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer--container {
  padding: 180px 120px 65px 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  align-items: flex-start
}

.footer--heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  margin-bottom: 15px;
}

.footer--ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer--ul > li > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.footer--el > p {
  font-weight: 400;
  font-size: 12.7025px;
  line-height: 16px;
  color: #404040;
}

.footer--el > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #9f9f9f;
}

.cards {
  display: flex;
  align-items: center;
  margin-top: 13.5px;
}

.cards > img, a {
  margin-right: 15px;
}
